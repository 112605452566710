h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.display-1,
.display-2,
.display-3,
.display-4 {
    @apply font-display;
    @apply mt-0;
}

h1,
.h1 {
    @apply text-4xl;
    @apply mb-12;
}

h2,
.h2 {
    @apply text-3xl;
    @apply mb-10;
}

h3,
.h3 {
    @apply text-2xl;
    @apply mb-8;
}

h4,
.h4 {
    @apply text-xl;
    @apply mb-6;
}

h5,
.h5 {
    @apply text-lg;
    @apply mb-4;
}

h6,
.h6 {
    @apply text-base;
    @apply mb-2;
}

.display-1 {
    @apply text-8xl;
    @apply mb-32;
}

.display-2 {
    @apply text-7xl;
    @apply mb-24;
}

.display-3 {
    @apply text-6xl;
    @apply mb-20;
}

.display-4 {
    @apply text-5xl;
    @apply mb-16;
}

.markdown-body {
    @apply max-w-none;
}

.markdown-body a {
    @apply text-blue-600 underline;
}

.markdown-body strong {
    @apply font-bold;
}

.markdown-body em {
    @apply italic;
}

.markdown-body ul {
    @apply list-disc list-inside my-4;
}

.markdown-body ol {
    @apply list-decimal list-inside my-4;
}

.markdown-body blockquote {
    @apply border-l-4 border-gray-300 pl-4 italic;
}

.markdown-body code {
    @apply bg-gray-100 px-1 rounded;
}
