a.link {
    @apply font-semibold;
    @apply text-indigo-500 dark:text-indigo-300;
}

a.link:hover {
    @apply underline;
    @apply text-indigo-400;
}

a.link:focus {
    @apply text-indigo-800 dark:text-indigo-400;
}

a.link:visited {
    @apply text-rose-500 dark:text-rose-400;
}
