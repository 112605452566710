html,
body {
    @apply h-full;
}

body {
    @apply bg-slate-100;
    @apply text-slate-900;
}

body.dark {
    @apply bg-zinc-900;
    @apply text-zinc-200;
}
